@import "./katapult_login/com_users/_all-com_users";
@import "./katapult_login/system-message";

html, body {
  height: 100%
}

body {
  background-image: url(https://source.unsplash.com/collection/8844283/1920x1080);
  background-size: cover;
  background-position: top right;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
}

.logout button {
  width: 100%;
}

label {
  font-weight: normal;
}
button {
  font-size: 18px !important;
}

::placeholder {
  color:    #fff;
  opacity: 0.7;
}
 
.alert-warning {
  color: #e64662;
  background-color: #fff;
  border-color: #fff;
}
