.view-profile {
  height: 100%;

  .container {
    height: 100%;
  }

  .row {
    height: 100%; display: flex; align-items: center;
  }
}

.profile {
  background: #e64662;
  padding: 2rem;
  border-radius: 5px;
  color: #fff;
  font-weight: normal;
  font-family: 'Roboto', sans-serif;

  label {
    display: none;
  }

  input {
    width: 100%;
    padding: 5px 10px;
    border: none;
    border-bottom: 2px solid #fff;
    background: none;
    box-shadow: none;
    outline: none;
  }

  &__submit {
    display: flex;
    justify-content: flex-end;
  }

  h2 {
    margin-top: 0;
    margin-bottom: 2rem;
  }

  button {
    margin-top: 30px;
    border-radius: 5px;
    width: 100%;
    color: #e64662;
    background: #fff;
    border: 2px solid #fff;
    font-weight: bold;
    font-size: 18px !important;
    max-width: 250px;

    &:hover {
      background: none;
      color: #fff;
      border: 2px solid #fff;
    }
  }

  &__cancel {
    margin-top: 30px;
    border-radius: 5px;
    width: 100%;
    color: #fff;
    background: transparent;
    border: 2px solid #fff;
    font-weight: bold;
    font-size: 18px !important;
    max-width: 250px;
    display: inline-block;
    margin-left: 1rem;
    padding: 6px 12px;
    margin-bottom: 0;

    &:hover {
      background: #fff;
      color: #e64662;
      border: 2px solid #fff;
      text-decoration: none;
    }
  }

  p {
    opacity: 0.7;
    margin-bottom: 2rem;
  }

  a {
    color: #fff;
    text-decoration: none;

    &:hover {
      color: #fff;
      text-decoration: underline;
    }
  }

}